.ant_table  {
  :global {
    .ant-table-sticky-holder { 
      top: 0 !important;
    }
  }
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: #f9fafc;
}

 .ant-table-tbody > tr:hover > td {
   background-color: #fafafa !important;
  //  color: #ffffff;
 }

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 8px 8px;
  top: 0 !important;
}

.ant-table-cell-fix-sticky {
  top: 0 !important;
}

.ant-table-summary {
  position: sticky;
  bottom: 0;
  z-index: 100;
  background: #fff;
}

.total_bold {
  font-weight: 600;
}

.ant-table-sticky-holder {
  top: 0 !important;
}

.align_right {
  text-align: right;
}
