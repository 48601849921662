.ant_table {
  :global {
    .ant-table-sticky-holder {
      top: 0 !important;
    }
    @media screen and (max-width: 872px) {
      margin-bottom: 28px;
    }
    @media screen and (max-width: 872px) {
      .ant-pagination.ant-pagination-mini.ant-table-pagination.ant-table-pagination-right {
        position: fixed;
        display: flex;
        margin-top: auto;
        justify-content: center;
        bottom: 0px;
        width: 100%;
        background-color: #f9fafc;
        height: 40px;
        margin: 0;
        text-align: center;
        align-items: center;
        z-index: 1000;
      }
    }
  }
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: #f9fafc;
}

.ant-table-tbody > tr:hover > td {
  background-color: #fafafa !important;
  //  color: #ffffff;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 8px 8px;
  top: 0 !important;
}

.ant-table-cell-fix-sticky {
  top: 0 !important;
}

.ant-table-summary {
  position: sticky;
  bottom: 0;
  // z-index: 100;
  background: #fff;
}

.total_bold {
  font-weight: 600;
  @media screen and (max-width: 872px) {
    font-size: 11px;
    white-space: nowrap;
  }
}

.ant-table-sticky-holder {
  top: 0 !important;
}

.align_right {
  text-align: right;
}

.bg_gray {
  &:nth-child(n) {
    background-color: #e8e8e8;
    z-index: 1;
  }
}

.bg_gray_even {
  &:nth-child(n) {
    background-color: #e8e8e8;
    z-index: 1;
  }
}

.column_gray {
  &:nth-child(n) {
    background-color: #e8e8e8;
    z-index: 1;
  }
}

.summary_cell {
  display: flex;
  flex-direction: column;
}

.buttons_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
