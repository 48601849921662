@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.root {
  margin-top: 76px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h1 {
  color: var(--text-text-neutral-primary, #1d1d1f);
  font-family: 'Inter', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;

  @include media-down($md) {
    font-size: 32px;
  }

  span {
    color: var(--text-text-action-primary, #1890ff);
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 57.6px */

    @include media-down($md) {
      font-size: 32px;
    }
  }
}

.info {
  margin-top: 30px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  align-self: stretch;
  color: #a9a9a9;

  @include media-down($md) {
    font-size: 14px;
  }
}

.picture {
  display: flex;
  justify-content: center;
  img {
    width: 640px;
    height: 640px;
    flex-shrink: 0;

    @include media-down($md) {
      width: 280px;
      height: 280px;
    }
  }
}
