@import '../../styles/mixins';
@import '../../styles/variables';

.layout {
  padding-right: 135px;
  padding-left: 135px;
  width: 100%;

  @include media-down($md) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.ant_calendar {
  @media (max-width: 480px) {
    .ant-calendar-range {
      width: 320px;
    }
    .ant-calendar-range-part {
      width: 100%;
    }
  }
}
