@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.root {
  padding-top: 50px;
  padding-bottom: 100px;
  padding-right: 135px;
  padding-left: 135px;
  margin-top: 100px;
  gap: 50px;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: var(--background-bg-action-primary, #1890ff);
  width: 100%;

  @include media-down($md) {
    padding-right: 20px;
    padding-left: 20px;
  }

  h2 {
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 43.2px */

    @include media-down($md) {
      font-size: 28px;
    }
  }
}

.cardList {
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  @include media-down($md) {
    flex-wrap: nowrap;
    gap: 8px;
    overflow-x: scroll;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 130px;
  width: 274px;
  padding: 20px;
  border-radius: 10px;
  border: 1px #1890ff solid;
  gap: 10px;
}

// .card:not(:last-child) {
//   margin: 10px; /* Adjust the margin as needed */
//   border-right: 1px solid #fff;
// }

.cardTitle {
  color: #fff;
  font-size: 24px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  margin-top: 21px;
  align-self: stretch;
  text-align: center;

  @include media-down($md) {
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
}
