@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.root {
  padding-top: 20px;
  padding-right: 135px;
  padding-left: 135px;
  gap: 150px;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: var(--background-bg-neutral-primary, #f9fafc);
  width: 100%;

  @include media-down($md) {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 30px;
  }
}

.logoFooter {
  display: flex;
  flex-direction: column;
  // width: 120px;
  // height: 32px;
  margin: 0px 62px 10 -10px;
  background: rgba(255, 255, 255, 0.3);
}

.logoFooter-svg {
  height: 32px;
  width: 100px;
  margin: 8px 0 0 3px;
}

.cardList {
  display: flex;
  flex-direction: column;
  .cardTitle {
    color: var(--text-text-neutral-secondary-5-a, #5a5a5a);
    text-align: start;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    cursor: pointer;
  }

  @include media-down($md) {
    display: none;
  }
}
