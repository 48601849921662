@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

h2 {
  color: var(--text-text-neutral-primary, #1d1d1f);
  font-family: 'Inter', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 43.2px */

  @include media-down($md) {
    font-size: 28px;
  }
}

.info {
  color: var(--text-text-neutral-secondary, #a9a9a9);
  text-align: start;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */

  @include media-down($md) {
    font-size: 16px;
  }
}

.cardList {
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
  display: flex;
  flex-wrap: wrap;
}

.card {
  display: flex;
  height: 69px;
  width: 363px;
  padding: 20px;
  background: #f9fafc;
  border-radius: 10px;
  border: 1px #1890ff solid;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  @include media-up($xxl) {
    width: 500px;
  }

  @include media-down($md) {
    width: 100%;
  }
}

.cardActive {
  background: #1890ff;
  display: flex;
  height: 69px;
  width: 363px;
  padding: 20px;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  @include media-up($xxl) {
    width: 500px;
  }

  @include media-down($md) {
    width: 100%;
  }
}

.cardActiveTitle {
  color: #fff;
  font-size: 24px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  margin-top: 21px;

  @include media-down($md) {
    font-size: 16px;
  }
}

.cardTitle {
  color: #5a5a5a;
  font-size: 24px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  margin-top: 21px;

  @include media-down($md) {
    font-size: 16px;
  }
}
