@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.root {
  margin-top: 100px;
  padding-right: 135px;
  padding-left: 135px;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--background-bg-neutral-primary, #edf7ff);

  @include media-down($md) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

h2 {
  color: var(--text-text-neutral-primary, #1d1d1f);
  font-family: 'Inter', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 43.2px */

  @include media-down($md) {
    font-size: 28px;
  }
}

.formLayout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form {
  width: 370px;

  @include media-down($md) {
    width: 100%;
  }
}

.platforms {
  .desktop {
    margin-top: 0px;
    position: relative;
    width: 591.631px;
    flex-shrink: 0;
    z-index: 1;
  }

  .phone {
    position: relative;
    margin-bottom: -138px;
    margin-left: -100px;
    width: 223.302px;
    // height: 451.514px;
    z-index: 1;
  }

  @include media-down($md) {
    display: none;
  }
}
