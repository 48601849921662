.wrapper {
  display: flex;
  background: #ffffff;
  flex-direction: column;
}

[data-theme='dark'] .site-layout-content {
  background: #141414;
}

.top_bar {
  display: flex;
  justify-items: center;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #1d1d1f;
}

.row {
  display: flex;
  flex-direction: row;
}

// .form {
//   max-width: 1051px;
//   width: 'auto';
//   margin-top: 24px;

//   .formData {
//     margin-top: 24px;
//     padding: 24px 0 0 24px;
//     background-color: #f6f6f6;
//   }
// }
// .actions {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
// }

.form {
  max-width: 1021px;
  width: auto;
  // margin-top: 24px;

  .formData {
    margin-top: 24px;
    padding: 24px 0 0 24px;
    background-color: #f6f6f6;
  }

  .manage {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .item {
      margin-right: 224px;
      display: flex;
      flex-direction: column;
    }
  }
}
