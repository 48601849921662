.company_layout_content {
  padding: 24px;
  display: flex;
  background: #ffffff;
  flex-direction: column;
}

[data-theme='dark'] .site-layout-content {
  background: #141414;
}

.top_bar {
  display: flex;
  justify-items: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.wrapper {
  display: flex;
  width: 100%;
}

.title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1d1d1f;
}

.company_filter_btn {
  position: sticky;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.export_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
