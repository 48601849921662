@mixin media-up($value) {
  @media (min-width: $value) {
    @content;
  }
}

@mixin media-down($value) {
  @media (max-width: $value) {
    @content;
  }
}
