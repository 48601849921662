@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.root {
  display: flex;
  flex-direction: column;
}

h2 {
  color: var(--text-text-neutral-primary, #1d1d1f);
  font-family: 'Inter', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 43.2px */

  @include media-down($md) {
    font-size: 28px;
  }
}

.cardList {
  margin-top: 50px;
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
  width: 100%;
  height: 100%;

  @include media-down($md) {
    flex-wrap: nowrap;
    gap: 8px;
    overflow-x: scroll;
    gap: 40px;
  }
}

.card {
  width: 225px;
  height: auto;
  @include media-up($xxl) {
    width: 350px;
  }
}

.cardTitle {
  color: var(--text-text-neutral-primary, #1d1d1f);
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  text-align: center;

  @include media-down($md) {
    font-size: 20px;
  }
}

.notActive {
  color: var(--text-text-neutral-secondary, #a9a9a9);
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;

  @include media-down($md) {
    font-size: 20px;
  }
}

.line {
  margin-top: 20px;
  border-bottom: 3px #1890ff solid;
}

.notActiveline {
  margin-top: 20px;
  border-bottom: 3px solid #e6e6e6;
}

.list {
  display: flex;
  flex-direction: column;
}

.item {
  margin-top: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  .activeItem {
    color: var(--text-text-neutral-secondary-5-a, #5a5a5a);
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 300;
    line-height: 150%; /* 30px */
    margin: 0;
    padding: 0;

    @include media-down($md) {
      font-size: 16px;
    }
  }

  .soonItem {
    color: var(--text-text-neutral-secondary-5-a, #5a5a5a);
    text-align: center;
    font-size: 20px;
    font-weight: 200;
    line-height: 150%; /* 30px */
  }

  @include media-down($md) {
    font-size: 16px;
  }
}
