.ant_calendar {
  @media (max-width: 480px) {
    .ant-calendar-range {
      width: 320px;
    }
    .ant-calendar-range-part {
      width: 100%;
    }
  }
}

.verticalRangePicker {
  display: inline-block;
  width: 100%;
}

.verticalRangePicker .ant-picker-dropdown {
  left: 0 !important;
  top: 100% !important;
  width: 100% !important;
}

.buttons_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter_container {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.user-filter-container {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  /* margin-left: 24px; */
}

.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: unset;
}

.ant-popover-placement-bottom .ant-popover-arrow,
.ant-popover-placement-bottomLeft .ant-popover-arrow,
.ant-popover-placement-bottomRight .ant-popover-arrow {
  top: unset;
  transform: unset;
}

label {
  margin-bottom: 8px;
  color: #1d1d1f;
  font-size: 14px;
}

.period {
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
}

.required:after {
  content: ' *';
  color: #ff4d4f;
}
