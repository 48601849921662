.ant_table {
  :global {
    .ant-table-sticky-holder {
      top: 0 !important;
    }
    @media screen and (max-width: 872px) {
      margin-bottom: 28px;
    }
    @media screen and (max-width: 872px) {
      .ant-pagination.ant-pagination-mini.ant-table-pagination.ant-table-pagination-right {
        position: fixed;
        display: flex;
        margin-top: auto;
        justify-content: center;
        bottom: 0px;
        width: 100%;
        background-color: #f9fafc;
        height: 40px;
        margin: 0;
        text-align: center;
        align-items: center;
        z-index: 1000;
      }
    }
  }
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: #f9fafc;
}

.ant-table-tbody > tr:hover > td {
  background-color: #fafafa !important;
  //  color: #ffffff;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 8px 8px;
  top: 0 !important;
}

.ant-table-cell-fix-sticky {
  top: 0 !important;
}

.ant-table-summary {
  position: sticky;
  bottom: 0;
  // z-index: 100;
  background: #fff;
}

.total_bold {
  font-weight: 600;
  @media screen and (max-width: 872px) {
    font-size: 11px;
    white-space: nowrap;
  }
}

.ant-table-sticky-holder {
  top: 0 !important;
}

.align_right {
  text-align: right;
}

.bg_gray {
  &:nth-child(n) {
    background-color: #e8e8e8;
    z-index: 1;
  }
}

.bg_gray_even {
  &:nth-child(n) {
    background-color: #e8e8e8;
    z-index: 1;
  }
}

.column_gray {
  &:nth-child(n) {
    background-color: #e8e8e8;
    z-index: 1;
  }
}

.summary_cell {
  display: flex;
  flex-direction: column;
}

.card_body {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  align-items: baseline;
  overflow-x: auto;
  font-size: 11px;
}

.market_card_title {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;
  margin: 0;
  font-weight: 700;
  position: sticky;
  left: 0;
  z-index: 10;
  background-color: #fff;
}

.city_card_title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  text-align: start;
  overflow: hidden;
  white-space: initial;
  text-overflow: ellipsis;
  height: 90px;
  margin: 0;
  padding: 4px 0 0 10px;
  background-color: #e8e8e8;
}

.share_cell_item {
  width: 100%;
  text-align: end;
  height: 38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cell_item {
  padding: 0 10px 0 0;
  margin: 0;
  text-align: end;
  width: 100%;
  white-space: nowrap;
}

.text {
  margin: 0;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text_gray {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: #a9a9a9;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
