.company_layout_content {
  padding: 24px;
  display: flex;
  background: #ffffff;
  flex-direction: column;

  @media screen and (max-width: 872px) {
    padding: 12px;
  }
}

[data-theme='dark'] .site-layout-content {
  background: #141414;
}

.top_bar {
  display: flex;
  justify-items: center;
  // align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;

  @media screen and (max-width: 872px) {
    margin-bottom: 0px;
  }
}

.wrapper {
  display: flex;
  width: 100%;
}

.title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  // font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1d1d1f;
}

.company_filter_btn {
  @media screen and (max-width: 872px) {
    width: 50px;
    margin-bottom: 16px;
  }

  position: sticky;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.export_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
