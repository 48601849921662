.ant_table {
  :global {
    .ant-table-sticky-holder {
      top: 0 !important;
    }
    @media screen and (max-width: 872px) {
      margin-bottom: 28px;
    }
    @media screen and (max-width: 872px) {
      .ant-pagination.ant-pagination-mini.ant-table-pagination.ant-table-pagination-right {
        position: fixed;
        display: flex;
        margin-top: auto;
        justify-content: center;
        bottom: 0px;
        width: 100%;
        background-color: #f9fafc;
        height: 40px;
        margin: 0;
        text-align: center;
        align-items: center;
        z-index: 1000;
      }
    }
    .react-resizable {
      position: relative;
      background-clip: padding-box;
    }

    .react-resizable-handle {
      position: absolute;
      right: -5px;
      bottom: 0;
      z-index: 1;
      width: 10px;
      height: 100%;
      cursor: col-resize;
    }
  }
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: #f9fafc;
}

.ant-table-tbody > tr:hover > td {
  background-color: #fafafa !important;
  //  color: #ffffff;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 8px 8px;
  top: 0 !important;
}

.ant-table-cell-fix-sticky {
  top: 0 !important;
}

.ant-table-summary {
  position: sticky;
  bottom: 0;
  z-index: 100;
  background: #fff;
}

.total_bold {
  font-weight: 600;
}

.ant-table-sticky-holder {
  top: 0 !important;
}

.align_right {
  text-align: right;
}

.cell_primary {
  font-size: 12;
  margin: 0;
  padding: 0;
  text-overflow: 'ellipsis';
  white-space: 'nowrap';
}

.cell_secondary {
  margin: 0;
  padding: 0;
  font-size: 12;
  color: '#A9A9A9';
  text-overflow: 'ellipsis';
  white-space: 'nowrap';
  font-style: 'normal';
  font-weight: 400;
}
