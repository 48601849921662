@import '../../../styles/mixins';
@import '../../../styles/variables';

.logo {
  display: flex;
  float: left;
  width: 120px;
  height: 32px;
  margin: 0 62px 0 -10px;
  background: rgba(255, 255, 255, 0.3);
}

.logo-svg {
  height: 32px;
  width: 100px;
  margin: 8px 0 0 3px;
}

.headerHome {
  background-color: #f6f6f6 !important;
  z-index: 100;
  height: 50px !important;
  padding: 0 12px !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.contentHome {
  width: 85%;
  @include media-down($md) {
    margin: 0;
  }

  margin-left: 135px;
  margin-right: 135px;
}

.navList {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
    line-height: 48px;
    cursor: pointer;
  }
}
