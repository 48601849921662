@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.root {
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

h2 {
  color: var(--text-text-neutral-primary, #1d1d1f);
  font-family: 'Inter', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 43.2px */

  @include media-down($md) {
    font-size: 28px;
  }
}

.info {
  color: var(--text-text-neutral-secondary, #a9a9a9);
  text-align: start;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */

  @include media-down($md) {
    font-size: 16px;
  }
}

.picture {
  display: flex;
  justify-content: center;
  img {
    width: 1059px;
    height: 605px;
    flex-shrink: 0;

    @include media-up($xxl) {
      width: 1359px;
      height: 805px;
    }

    @include media-down($md) {
      width: 300px;
      height: 205px;
    }
  }
}
